<template>
    <b-card no-body class="d-flex flex-column p-3" style="row-gap: 16px">
        <div>
            <Header />
            <hr class="m-0" />
        </div>
        <h4>Applications</h4>
        <!-- Add a table with this fields: Review Date, Reviewed By, Risk Rating, Result, Comments -->
        <b-row>
            <b-col cols="12">
                <b-table
                    small
                    show-empty
                    :filter="filter"
                    striped
                    head-variant="light"
                    :items="applications"
                    hover
                    :fields="[
                        { key: 'id', label: 'Loan ID' },
                        // { key: 'nationalID', label: 'Applicant ID' },
                        { key: 'customerName', label: 'Applicant' },
                        // { key: 'coApplicantNationalID', label: 'CoApplicant ID' },
                        { key: 'coApplicantCustomerName', label: 'CoApplicant' },
                        { key: 'loanDescription', label: 'Loan Type' },
                        { key: 'loanAmount', label: 'Loan Amount' },
                        { key: 'agentName', label: 'Loan Officer' },
                        { key: 'customerAppliedAs', label: 'Applied as' },
                        { key: 'action', label: 'Action' },
                    ]"
                >
                    <template #cell(action)="data">
                        <b-button size="sm" variant="primary" @click="handleView(data.item)">View Detail</b-button>
                    </template>
                </b-table>

                <div class="pt-2 d-flex align-items-center">
                    <b-form-group
                        label="Per page"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="9"
                        label-size="sm"
                        label-class="fw-medium"
                        class="mb-0"
                    >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                        ></b-form-select>
                    </b-form-group>
                    <div class="ml-auto">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="applications.length"
                            :per-page="perPage"
                            align="fill"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import Header from '@/components/Header.vue';
export default {
    name: 'CustomerApplicationHistory',
    components: {
        Header,
    },
    data() {
        return {
            applications: [],
            perPage: 5,
            currentPage: 1,
            pageOptions: [5, 10, 15],
            filter: null,
        };
    },
    methods: {
        async fetchApplications() {
            const response = await this.$store.dispatch(
                'loan/applications/get_loanApplicationsByCustomerId',
                this.$route.params.customer,
            );
            this.applications = response.dataResult;
        },

        handleView(item) {
            this.$router.push(`/applications/list/${this.$route.params.customer}?loanApplicationId=${item.id}`);
        },
    },
    created() {
        this.fetchApplications();
    },
};
</script>
