<template>
    <b-overlay :show="loading" rounded="sm">
        <b-row v-if="!loading">
            <b-col cols="12">
                <b-card no-body class="mb-4">
                    <b-card-body class="p-3">
                        <b-tabs content-class="mt-3" align="left">
                            <b-tab title="Main" active lazy>
                                <CustomerMain />
                            </b-tab>

                            <b-tab title="Additional Details" lazy>
                                <CustomerAdditionalDetails />
                            </b-tab>

                            <b-tab title="Documents" lazy>
                                <CustomerDocuments />
                            </b-tab>

                            <b-tab title="Comments" lazy>
                                <CustomerComments />
                            </b-tab>

                            <b-tab title="Employment" lazy>
                                <CustomerEmployment />
                            </b-tab>

                            <b-tab title="References" lazy>
                                <CustomerReferences />
                            </b-tab>

                            <b-tab title="Compliance & Risk" lazy>
                                <CustomerComplianceRisk />
                            </b-tab>

                            <b-tab title="Screening" lazy>
                                <CustomerScreening />
                            </b-tab>

                            <b-tab title="Application History" lazy>
                                <CustomerApplicationHistory />
                            </b-tab>

                            <b-tab title="Loan History" lazy>
                                <CustomerLoanHistory />
                            </b-tab>
                        </b-tabs>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </b-overlay>
</template>

<script>
import CustomerMain from '@/components/customers/CustomerMain.vue';
import CustomerAdditionalDetails from '@/components/customers/CustomerAdditionalDetails.vue';
import CustomerDocuments from '@/components/customers/CustomerDocuments.vue';
import CustomerComments from '@/components/customers/CustomerComments.vue';
import CustomerEmployment from '@/components/customers/CustomerEmployment.vue';
import CustomerComplianceRisk from '../../components/customers/CustomerComplianceRisk.vue';
import CustomerLoanHistory from '../../components/customers/CustomerLoanHistory.vue';
import CustomerReferences from '../../components/customers/CustomerReferences.vue';
import CustomerApplicationHistory from '../../components/customers/CustomerApplicationHistory.vue';
import CustomerScreening from '../../components/customers/CustomerScreening.vue';

export default {
    name: 'CustomerDetail',
    components: {
        CustomerMain,
        CustomerAdditionalDetails,
        CustomerDocuments,
        CustomerComments,
        CustomerEmployment,
        CustomerComplianceRisk,
        CustomerLoanHistory,
        CustomerReferences,
        CustomerApplicationHistory,
        CustomerScreening,
    },
    data: () => ({
        loading: false,
    }),
    methods: {
        async getData() {
            this.loading = true;
            await this.$store.dispatch('customer/get_customer', this.$route.params.customer);
            await this.$store.dispatch('customer/productRequest/get_processResquest', this.$route.params.customer);
            this.loading = false;
        },
    },
    created() {
        this.getData();
    },
};
</script>
